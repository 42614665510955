import {configDefaultWithToken, deleteApiCall, getApiCall, postApiCall, postApiCallWithoutBody} from './base';
import {CompanyCreate, CompanyInvitation, CompanyMemberUpdater} from '../types/company';
import {CardProfileApiFilter} from '../types/userCardProfile';

const companyUrl = '/api/company';

const getCompanyMembersUrl = (id: number) => `${companyUrl}/${id}/members`;
const getCompanyMemberUrl = (companyId: number, userId: number) => `${companyUrl}/${companyId}/member/${userId}`;
const sendCompanyInvitationUrl = (id: number) => `${companyUrl}/${id}/members/invite`;

const acceptCompanyInvitationUrl = (code: string) => `${companyUrl}/invitation-accepted/${code}`;

const getBusinessCompanyCardProfilesUrl = (userId: number, companyId: number) => `${companyUrl}/company-card-profiles/basic/${userId}/company/${companyId}`;
const getBusinessCompanyCardProfilesForMemberUrl = (userId: number, companyId: number) => `${companyUrl}/company-member-card-profiles/basic/${userId}/company/${companyId}`;

const getBusinessMyCardProfilesUrl = (userId: number, companyId: number) => `${companyUrl}/card-profiles/basic/${userId}/company/${companyId}`;

const assignCardToCompanyUrl = (companyId: number, cardProfileId: number) => `${companyUrl}/${companyId}/assign-card/${cardProfileId}`;

const setUserActiveCompanyUrl = (companyId: number) => `${companyUrl}/${companyId}/active`;


export const createNewCompany = async (data: CompanyCreate) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(companyUrl, data, config);
};

export const getCompanyMembers = async (companyId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(getCompanyMembersUrl(companyId), config);
};

export const updateCompanyMemberRole = async (companyId: number, data: CompanyMemberUpdater) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(getCompanyMembersUrl(companyId), data, config);
};


export const sendCompanyInvitation = async (companyId: number, data: CompanyInvitation) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(sendCompanyInvitationUrl(companyId), data, config);
};

export const acceptCompanyInvitation = async (code: string) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCallWithoutBody(acceptCompanyInvitationUrl(code), config);
};

export const getCompanyCardProfiles = async (userId: number, companyId: number, filter: CardProfileApiFilter) => {

    const config = {
        ...(await configDefaultWithToken()),
        params: {
            searchByName: filter.searchByName,
            status: filter.status,
            page: filter.page
        }
    };

    return await getApiCall(getBusinessCompanyCardProfilesUrl(userId, companyId), config);
};

export const getCompanyCardProfilesForCompanyMember = async (userId: number, companyId: number, filter: CardProfileApiFilter) => {

    const config = {
        ...(await configDefaultWithToken()),
        params: {
            searchByName: filter.searchByName,
            status: filter.status,
            page: filter.page
        }
    };

    return await getApiCall(getBusinessCompanyCardProfilesForMemberUrl(userId, companyId), config);
};

export const getBusinessMyCardProfiles = async (userId: number, companyId: number, filter: CardProfileApiFilter) => {

    const config = {
        ...(await configDefaultWithToken()),
        params: {
            searchByName: filter.searchByName,
            status: filter.status,
            page: filter.page
        }
    };

    return await getApiCall(getBusinessMyCardProfilesUrl(userId, companyId), config);
};

export const assignCardToCompany = async (companyId: number, cardProfileId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(assignCardToCompanyUrl(companyId, cardProfileId), {}, config);
};

export const setUserActiveCompany = async (companyId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(setUserActiveCompanyUrl(companyId), {}, config);
};

export const removeCompanyMember = async (companyId: number, userId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(getCompanyMemberUrl(companyId, userId), config);
};
